import { Accordion, AccordionTab } from 'primereact/accordion';

function CommonFAQ() {
    return (
        <div className="sixth_section outer_section_detail">
            <div className="hdr_txt mb-4 redHatFont">FAQ</div>
            <div>
                <Accordion activeIndex={0}>
                    <AccordionTab header="Is there any lock-up period for funds?">
                        <p className="m-0">
                            No, there is no lock-up period in this vault. You
                            can withdraw your funds at any time without any
                            restriction or penalty.
                        </p>
                    </AccordionTab>
                    <AccordionTab header="What assets are the yields paid in?">
                        <p className="m-0">
                            The yields are paid in the denomination asset of the
                            strategy and are compounded automatically.
                            Unharvested rewards can be temporarily held in the
                            form of farmed assets.
                        </p>
                    </AccordionTab>
                    <AccordionTab header="What are the fees associated with using the vault?">
                        <p className="m-0">
                            Rivera Vaults applies a performance fee that is
                            automatically claimed during vault compounding. This
                            means that all APR and portfolio balances you see in
                            our platform are already adjusted for any fees
                            charged. Selected vaults have an exit fee which will
                            be deducted during the withdrawal. All fees are
                            displayed in the deposit & withdrawal section for
                            full transparency.
                        </p>
                    </AccordionTab>
                    <AccordionTab header="I'm having trouble using the app. What should I do?">
                        <p className="m-0">
                            Join our Discord community and help us improve our
                            app by reporting your issue. Win exclusive rewards,
                            bounty & more for your contribution.
                        </p>
                    </AccordionTab>
                    <AccordionTab header="I love Rivera! How can I get involved?">
                        <p className="m-0">
                            We are recruiting DeFi pros who can help us redefine
                            the future of DeFi liquidity management. Join us in
                            testing the Rivera protocol and contributing to its
                            development. We are awarding exclusive OG NFTs to
                            the top 100 testers. Get started by joining our
                            Discord.
                        </p>
                    </AccordionTab>
                </Accordion>
            </div>
        </div>
    );
}

function Risk() {
    return (
        <div className="fourth_section outer_section_detail">
            <span className="hdr_txt mb-2 redHatFont">Risk</span>
            <div className="mt-3">
                <ul className="pdng_18">
                    <li className="mrgn_btm_15">
                        <span className="fnt_wgt_600">Principal Risk:</span>{" "}
                        Impermanent loss associated with AMM liquidity farming
                        can lead to a loss of principal amount.
                    </li>
                    <li className="mrgn_btm_15">
                        <span className="fnt_wgt_600">Smart Contract Risk:</span>
                        There is a risk of smart contract failure in the
                        underlying vault or the protocols we work with.
                    </li>
                </ul>
            </div>
        </div>
    );
}
function RewardGrid() {
    return (
        <div className='rewardsFaq'>
            <h2 className='mb-4 redHatFont history_txt'>FAQs</h2>
            <div className='history_txt mb-4 redHatFont'></div>
            <Accordion activeIndex={0}>
                <AccordionTab header='What is xRIV?'>
                    <p className='m-0'>
                        xRIV (Rivera Experience Token) is the pre-mined RIV token created to incentivize early adopters of the ecosystem. Users can claim their xRIV rewards after the launch of the RIV token in a 1:1 ratio.
                    </p>
                </AccordionTab>
                <AccordionTab header='Do I get RIV tokens or its derivatives?'>
                    <p className='m-0'>
                        You will receive a non-transferrable xRIV token which can be redeemed into RIV tokens at a 1:1 ratio after TGE (subject to vesting).
                    </p>
                </AccordionTab>
                <AccordionTab header='How often are the rewards updated?'>
                    <p className='m-0'>
                        Rewards are allocated and listed on the rewards page at the end of each pre-mine epoch. Each epoch is expected to last 7 to 14 days.
                    </p>
                </AccordionTab>
                <AccordionTab header='When will the TGE be?'>
                    <p className='m-0'>
                        The TGE is expected in Q3 2024. More details will be announced via official channels.
                    </p>
                </AccordionTab>
                <AccordionTab header='I need more clarifications, what should I do?'>
                    <p className='m-0'>
                        Read our <a href='https://rivera.gitbook.io/docs/tokenomics/usdriv' target='_blank' rel='noopener noreferrer'>docs</a> or ask the team in our <a href='https://discord.com/invite/DkdZ5DyJga' target='_blank' rel='noopener noreferrer'>discord</a>.
                    </p>
                </AccordionTab>
            </Accordion>
        </div>
    )
}

export { CommonFAQ, Risk, RewardGrid };