import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom'; // Import useNavigate from React Router
import coinR from "../assets/images/coinRewards.svg"
import { useAccount } from "wagmi";

interface ApiResponse {
    _id: string;
    walletAddress: string;
    xriv: number;
}

export default function RewardsBtn() {
    const navigate = useNavigate();
    const { address } = useAccount();

    const [isDataLoading, setIsDataLoading] = useState(false);
    const [isTest, setIsTest] = useState(false);
    const [portfolio, setPortfolio] = useState<number | string>('');
    const [totalVault, setTotalVault] = useState<number>(0);
    const [apiResponse, setApiResponse] = useState<ApiResponse | null>(null);

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsDataLoading(true);
                const response = await fetch(`https://api.rivera.money/xriv/users/${address}`);
                const data = await response?.json();
                setApiResponse(data);
                setPortfolio(data.xriv);

                if (data.history && Array.isArray(data.history)) {
                    setTotalVault(data.history.reduce((total: any, item: { allocation: any; }) => total + Number(item.allocation), 0));
                } else {
                    setTotalVault(0);
                }

                setIsDataLoading(false);
            } catch (error) {
                setIsDataLoading(false);
            }
        };
        fetchData();
    }, [address]);


    const openRewardsPage = () => {
        navigate('/rewards', { state: { portfolio } });
    };

    const handleIsTest = () => {
        setIsTest(!isTest);
    };

    return (
        <button className='btn-riv-primary_rewards fixed_w dexDropBtn connect_btn_wdth_rewards flexT align-items-center'
            onClick={openRewardsPage} type="button">
            <img className="inmdC" src={coinR} alt="Coin Rewards" onLoad={handleIsTest}/>
            <div className="flexT align-items-center imMarRight mt-2">
                <h6 className="redHatFont fnt_wgt_600 secondary_color notMobileDevice whitespace-nowrap mr-05">{portfolio || 0 }{" "}xRIV</h6>
            </div>
        </button>
    );
}
