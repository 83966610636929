import React from 'react';
import { ProgressBar } from 'primereact/progressbar';

interface CombinedProgressBarProps {
  productAProgress: number;
  productBProgress: number;
}

const CombinedProgressBar: React.FC<CombinedProgressBarProps> = ({ productAProgress, productBProgress }) => {
  return (
    <div className="card imDark">
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div style={{ flex: '1' }}>
          <ProgressBar showValue={false} value={productAProgress} style={{ backgroundColor: '#55adff', height: '15px' }}></ProgressBar>
        </div>
      </div>
    </div>
  );
};

export default CombinedProgressBar;
