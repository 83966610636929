import React, { useState, useEffect, useContext } from "react";
import { Dropdown } from "primereact/dropdown";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import dexExch from "../assets/images/dexExch.svg";
import fusionX from "../assets/images/dex/fusionxDex.svg";
import agni from "../assets/images/dex/agniDex.svg";
import swapsicle from "../assets/images/dex/swapNewSha.png";
import aperture from "../assets/images/dex/apertureDex.svg";
import uniswap from "../assets/images/dex/uniSwap.svg";
import glowswap from "../assets/images/dex/glowswap.svg";
import proxyswap from "../assets/images/dex/proxyswap.svg";
import corex from "../assets/images/dex/corexdexx.svg";
import { backendApiUrlBase } from "../constants/global";

interface DEX {
  name: string;
  code: string;
  image: string;
  url: string;
  url1?: string;
}

const DEXContext = React.createContext<{
  selectedDEX: DEX | null;
  setSelectedDEX: React.Dispatch<React.SetStateAction<DEX | null>>;
}>({
  selectedDEX: null,
  setSelectedDEX: () => {},
});

export const useDEXContext = () => useContext(DEXContext);

export default function FilterDemo() {
  const navigate = useNavigate();

  function navigateToPage(url: string) {
    navigate(url);
  }

  const [selectedDEX, setSelectedDEX] = useState<DEX | null>(null);

  useEffect(() => {
    const getVaultAddressFromURL = () => {
      const url = window.location.href;
      const vaultAddress = url.substring(url.lastIndexOf("/") + 1);
      return vaultAddress;
    };

    const fetchDataAndMatchDEX = async () => {
      const vaultAddress = getVaultAddressFromURL();
      try {
        const response = await axios.get(`${backendApiUrlBase}vaults`);
        const matchedVault = response.data.find(
          (vault: any) => vault.identifier === vaultAddress
        );
        if (matchedVault) {
          setSelectedDEX(getDEXFromVault(matchedVault.dex));
        }
      } catch (error) {
        console.error("Error fetching vaults:", error);
      }
    };

    fetchDataAndMatchDEX();

    const storedDEX = localStorage.getItem("selectedDEX");
    if (storedDEX) {
      setSelectedDEX(JSON.parse(storedDEX));
    }

    const handleURLChange = () => {
      const url = window.location.href;
      const dexNames = ["FusionX", "Agni", "Swapsicle", "ApertureSwap", "Uniswap", "GlowSwap", "ProxySwap", "Corex",];
      const lowerCaseUrl = url.toLowerCase();
      const matchedDEX = dexNames.find((name) =>
        lowerCaseUrl.includes(name.toLowerCase())
      );
      if (matchedDEX) {
        setSelectedDEX(getDEXFromVault(matchedDEX));
      }
    };

    handleURLChange();
    window.addEventListener("popstate", handleURLChange);

    return () => {
      window.removeEventListener("popstate", handleURLChange);
    };
  }, []);

  const getDEXFromVault = (dexCode: string): DEX | null => {
    switch (dexCode) {
      case "FusionX":
        return { name: "FusionX", code: "FusionX", image: fusionX, url: "/discover/FusionX/mantle", };
      case "Agni":
        return { name: "Agni", code: "Agni", image: agni, url: "/discover/Agni/mantle", };
      case "Swapsicle":
        return { name: "Swapsicle", code: "Swapsicle", image: swapsicle, url: "/discover/Swapsicle/telos", };
      case "ApertureSwap":
        return { name: "Aperture", code: "ApertureSwap", image: aperture, url: "/discover/ApertureSwap/manta-pacific", };
      case "Uniswap":
        return { name: "Uniswap", code: "Uniswap", image: uniswap, url: "/discover/Uniswap/arbitrum", url1: "/discover/Uniswap/polygonPoS", };
      case "GlowSwap":
        return { name: "GlowSwap", code: "GlowSwap", image: glowswap, url: "/discover/GlowSwap/b-squared", };
      case "ProxySwap":
        return { name: "ProxySwap", code: "ProxySwap", image: proxyswap, url: "/discover/ProxySwap/degen", };
      case "Corex":
        return { name: "Corex", code: "Corex", image: corex, url: "/discover/Corex/coredao", };
      default:
        return null;
    }
  };

  const dex: DEX[] = [
    { name: "FusionX", code: "FusionX", image: fusionX, url: "/discover/FusionX/mantle", },
    { name: "Agni", code: "Agni", image: agni, url: "/discover/Agni/mantle" },
    { name: "Swapsicle", code: "Swapsicle", image: swapsicle, url: "/discover/Swapsicle/telos", },
    { name: "Aperture", code: "ApertureSwap", image: aperture, url: "/discover/ApertureSwap/manta-pacific", },
    { name: "Uniswap", code: "Uniswap", image: uniswap, url: "/discover/Uniswap/arbitrum", url1: "/discover/Uniswap/polygonPoS", },
    { name: "GlowSwap", code: "GlowSwap", image: glowswap, url: "/discover/GlowSwap/b-squared", },
    { name: "ProxySwap", code: "ProxySwap", image: proxyswap, url: "/discover/ProxySwap/degen", },
    { name: "Corex", code: "Corex", image: corex, url: "/discover/Corex/coredao", },
  ];

  const selectedDEXTemplate = (option: DEX) => {
    if (!option) {
      return (
        <div className="placeHolder redHatFont">
          <img alt={agni} src={dexExch} className={`imgDEXDrop flag flag-${agni.toLowerCase()}`} width={35} />
          <span className="redHatFont fnt_wgt_600 ml-5 imLight ">
            Explore by DEX
          </span>
        </div>
      );
    } else {
      return (
        <div className="placeHolder redHatFont">
          <img alt={option.name} src={option.image} className={`imgDEXDrop flag flag-${option.code.toLowerCase()}`} width={35} />
          <span className="redHatFont fnt_wgt_600 ml-5 imLight notMobileDevice">
            {option.name}
          </span>
        </div>
      );
    }
  };

  const DEXOptionTemplate = (option: DEX) => {
    return (
      <div className="flexdown redHatFont align-items-center">
        <div className="flexT redHatFont align-items-center">
          <img alt={option.name} src={option.image} className={`mr-2 flag flag-${option.code.toLowerCase()}`} style={{ width: "32px" }} />
          <div className="fnt_wgt_600 redHatFont">{option.name}</div>
        </div>
        {selectedDEX && selectedDEX.name === option.name && (
          <div className="green-circle"></div>
        )}
      </div>
    );
  };

  const handleDEXSelect = (option: DEX) => {
    setSelectedDEX(option);
    localStorage.setItem("selectedDEX", JSON.stringify(option));
    navigateToPage(option.url);
    window.location.reload();
  };

  return (
    <DEXContext.Provider value={{ selectedDEX, setSelectedDEX }}>
      <>
        <style>
          {`
          .p-dropdown-label {
          color: var(--text);
          font-weight: 500;
          }
          .p-dropdown {
          background-color: var(--body_background);
          color: var(--text);
          }
         `}
        </style>
        <Dropdown
          value={selectedDEX}
          onChange={(e) => handleDEXSelect(e.value as DEX)}
          options={dex}
          optionLabel="name"
          placeholder="SELECT"
          filter
          valueTemplate={selectedDEXTemplate}
          itemTemplate={DEXOptionTemplate}
          className="w-full dexD redHatFont connect_btn_wdth btn-riv-primary_dex connect_btn_wdth_dex"
          panelStyle={{ borderRadius: "0px" }}
          panelClassName="dropdown-panel"
        />
      </>
    </DEXContext.Provider>
  );
}
