import React, { useState, useEffect } from 'react';
import { RainbowKitProvider, lightTheme } from '@rainbow-me/rainbowkit';
import { RouterProvider } from 'react-router-dom';

interface AppContentProps {
    chains: any[];
    router: any;
}

const AppContent: React.FC<AppContentProps> = ({ chains, router }) => {
    const [connectedChainId, setConnectedChainId] = useState<number | null>(null);

    useEffect(() => {
        async function fetchConnectedChain() {
            if (window.ethereum) {
                try {
                    const chainId = await window.ethereum.request({ method: 'eth_chainId' });
                    const parsedChainId = parseInt(chainId, 16);
                    setConnectedChainId(parsedChainId);
                } catch (error) {
                    console.error(error);
                }
            }
        }

        fetchConnectedChain();

        const handleChainChanged = () => {
            fetchConnectedChain();
        };

        // Listen for chain change event
        window.ethereum && window.ethereum.on && window.ethereum.on('chainChanged', handleChainChanged);

        return () => {
            
            window.ethereum && window.ethereum.removeListener && window.ethereum.removeListener('chainChanged', handleChainChanged);
        };
    }, [connectedChainId]);


    return (
        <React.Fragment>
            <RainbowKitProvider
                initialChain={connectedChainId || 1}
                chains={chains}
                theme={lightTheme({
                    accentColor: '#7b3fe4',
                    accentColorForeground: 'white',
                    borderRadius: 'medium',
                    fontStack: 'system',
                    overlayBlur: 'small',
                })}
            >
                <RouterProvider router={router} />
            </RainbowKitProvider>
        </React.Fragment>
    );
};

export default AppContent;
