// DoughnutChartDemo.tsx
import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';

interface DoughnutChartProps {
  data: { value: number; color: string; image?: string | undefined; label: string }[][];
}
const DoughnutChartDemo: React.FC<DoughnutChartProps> = ({ data }) => {
  const [chartData, setChartData] = useState<any>({});
  const [chartOptions, setChartOptions] = useState<any>({});
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    // Simulate data loading delay (you can replace this with your actual data loading logic)
    const loadingTimeout = setTimeout(() => {
      const newData = {
        labels: data[0].map((item) => item.label),
        datasets: [
          {
            data: data[0].map((item) => item.value),
            backgroundColor: data[0].map((item) => item.color),
            hoverBackgroundColor: data[0].map((item) => item.color),
          },
        ],
      };

      const options = {
        cutout: '65%',
        plugins: {
          legend: {
            display: false,
          },
          tooltip: {
            enabled: true,
            bodyFont: {
              size: 20,
            },
          },
        },
      };

      setChartData(newData);
      setChartOptions(options);
      setIsLoading(false);
    }, 2000); // Adjust the loading duration as needed

    return () => clearTimeout(loadingTimeout);
  }, [data]);

  return (
    <div className="pie-container">
      {isLoading ? (
        <div className="skeleton-loader">
          <div className="skeleton-circle"></div>
        </div>
      ) : (
        <div className="cardD doughnut">
          <Chart type="doughnut" data={chartData} options={chartOptions} className="width_20" />
        </div>
      )}
    </div>
  );
};

export default DoughnutChartDemo;