import { useEffect, useState } from "react";
import rocketImg from "../../assets/images/rocket.png";
import { backendApiUrlBase } from "../../constants/global";
import { getFormatedData } from "../common/format";
import "../../App.css";

function Discover() {
  const viewDetailsbuttonVal = "Provide Liquidity --->";
  const [exchnageDetailList, setExchnageDetailList] = useState<any>([]);
  useEffect(() => {
    getExchangeDetails();
  }, []);

  const getExchangeDetails = async () => {
    const data = await fetch(`${backendApiUrlBase}vaults/stats`);
    let jsonData = await data.json();

    const aggregatedData = jsonData.reduce((acc: any, item: any) => {
      const dex = item.dex;
      if (!acc[dex]) {
        acc[dex] = { ...item };
      } else {
        acc[dex].totalVaults += item.totalVaults;
        acc[dex].tvlUSD += item.tvlUSD;
      }
      return acc;
    }, {});

    let aggregatedList = Object.values(aggregatedData);
    aggregatedList = aggregatedList
      .map((e: any) => {
        if (e?.dex === "FusionX") {
          e.exchangeImage = "/img/fusionNewDEX.svg";
        } else if (e?.dex === "Agni") {
          e.exchangeImage = "/img/agniNewDEX.svg";
        } else if (e?.dex === "ApertureSwap") {
          e.exchangeImage = "/img/apertureNewDEX.svg";
        } else if (e?.dex === "Swapsicle") {
          e.exchangeImage = "/img/swapsicleNewDEX.svg";
        } else if (e?.dex === "Uniswap") {
          e.exchangeImage = "/img/uniSwapNewDEX.svg";
        } else if (e?.dex === "GlowSwap") {
          e.exchangeImage = "/img/glownew.svg";
        } else if (e?.dex === "ProxySwap") {
          e.exchangeImage = "/img/proxynew.svg";
        } else if (e?.dex === "Corex") {
          e.exchangeImage = "/img/corexnew.png";
        }
        return e;
      })
      .sort(
        (a: { tvlUSD: number }, b: { tvlUSD: number }) => b.tvlUSD - a.tvlUSD
      );
    setExchnageDetailList(aggregatedList);
  };
  console.log("exchnageDetailList", exchnageDetailList);
  return (
    <>
      <div className="custom-container">
        <div className="second_section row mt-5 mb-5">
          <h4 className="valut_header  redHatFont fnt_wgt_600">
            Browse Exchanges
          </h4>
          {exchnageDetailList.map((e: any, index: any) => {
            return (
              <div className="card_wdth_50" key={index}>
                <div className="pddng_15 over_hidden  mb-3  outer_section">
                  <div className="dsp_start exchng_mrgn ">
                    <div>
                      <div className="mb-4 first_sec_heder1">{e.dex}</div>
                      <div className="flex-exchng">
                        <div className="mr_35">
                          <span className="redHatFont fnt_wgt_600">
                            TVL <br />{" "}
                          </span>
                          <span className="fnt_wgt_800 secondary_color">
                            ${getFormatedData(e.tvlUSD)}
                          </span>
                        </div>
                        <div className="redHatFont fnt_wgt_600">
                          Total Pairs <br />{" "}
                          <span className="fnt_wgt_800 ">{e.totalVaults}</span>
                        </div>
                      </div>
                    </div>
                    <div className="dex_img_margn" style={{ height: "16rem" }}>
                      <img
                        alt="dex img"
                        src={e.exchangeImage}
                        className="dex-image hgt_10"
                        onError={(e) => {
                          (e.target as HTMLImageElement).src = rocketImg;
                        }}
                      />
                    </div>
                  </div>
                  <div className="dsp_around mb-2  mt-4 ">
                    <div className="wdth_100 ">
                      <a
                        className="btn btn-riv-secondary view_btn_wdth"
                        href={`#/discover/${e.dex}/${e.chain}`}
                      >
                        {viewDetailsbuttonVal}
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

export default Discover;
