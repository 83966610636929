import "primereact/resources/themes/lara-light-indigo/theme.css";
import "primereact/resources/primereact.min.css";
import "@rainbow-me/rainbowkit/styles.css";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { WagmiConfig } from "wagmi";
import { chains, client } from "./setup/rainbowkit/wagmi";
import { Route, createHashRouter, createRoutesFromElements, } from "react-router-dom";
import RoutingLayout from "./setup/routing/RoutingLayout";
import Home from "./pages/dashboard/Home";
import VaultDetails from "./pages/common/VaultDetails";
import VaultRange from "./pages/admin/VaultRange";
import VaultManagerDetails from "./pages/admin/VaultManagerDetails";
import Discover from "./pages/discover/Discover";
import DexDetails from "./pages/discover/DexDetails";
import VaultList from "./pages/discover/VaultList";
import Quant from "./pages/quant/Quant";
import Reward from "./pages/rewards/rewards";
// import AirDrop from "./pages/airDrop/AirDrop";
import AppContent from "./AppContent";
import Manage from "./pages/manage/Manage";
import ExampleTable from "./pages/portfolio/portfolio";
import ManageDegen from "./pages/manageDegen/manageDegen";
import ManagePolygon from "./pages/managePolygon/managePolygon";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const router = createHashRouter(
  createRoutesFromElements(
    <Route path="/" element={<RoutingLayout />}>
      <Route index element={<Home />} />
      <Route path="dashboard" element={<Home />} />
      <Route path="rewards" element={<Reward />} />
      <Route path="earn/telos" element={<Manage />} />
      <Route path="earn/polygon" element={<ManagePolygon />} />
      <Route path="earn/degen" element={<ManageDegen />} />
      <Route path="portfolio" element={<ExampleTable />} />
      <Route path="discover" element={<Discover />} />
      <Route path="discover/:exchangeName" element={<DexDetails />}>
        <Route path=":chainName" element={<VaultList />} />
      </Route>
      <Route path="discover/:exchangeName/:chainName/:vaultAddress" element={<VaultDetails />} />
      <Route path="vaultManager/:vaultAddress" element={<VaultManagerDetails />} />
      <Route path="valutRangeSelector/:vaultAddress" element={<VaultRange />} />
      <Route path="vault/:vaultAddress" element={<VaultDetails />} />
    </Route>
  )
);

root.render(
  <WagmiConfig client={client}>
    <AppContent chains={chains} router={router} />
  </WagmiConfig>
);

reportWebVitals();
