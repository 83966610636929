import React, { useState, useEffect, useRef } from "react";
import { useSigner } from "wagmi";
import masterChef from "../../abi/out/MasterChefNew.sol/MasterChefNew.json";
import { ethers } from "ethers"; // Import ethers.js library
import ConnectBtn from "../../component/ConnectBtn";
import { Toast } from "primereact/toast";

interface PoolToken {
  index: number;
  share: string;
  address: string;
}

interface ManageBtnProps {
  vaultName: string;
  Img1: string;
  Img2: string;
  addressNew: string;
  symbol: string;
  rpc: string;
  denominationDecimal: number;
}

const ClaimButtonDegen: React.FC<ManageBtnProps> = ({
  vaultName,
  Img1,
  Img2,
  addressNew,
  symbol,
  rpc,
}) => {
  const [visible, setVisible] = useState(false);
  const [poolTokens, setPoolTokens] = useState<PoolToken[]>([]);
  const [vaultNumber, setVaultNumber] = useState<number | undefined>();
  const { data: signer } = useSigner();
  const [isTransactionOnGoing, setIsTransactionOnGoing] = useState(false);
  const toast = useRef<Toast>(null);

  const showDialog = () => {
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const showSuccess = (message: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showWarn = (header: string, message: string) => {
    toast.current?.show({
      severity: "warn",
      summary: header,
      detail: message,
      life: 3000,
    });
  };

  const showError = (message: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  // changes to be made
  useEffect(() => {
    setVaultNumber(
      symbol === "RIV-01-01-Y"
        ? 0
        : symbol === "RIV-01-02-Y"
        ? 1
        : symbol === "RIV-01-03-Y"
        ? 2
        : symbol === "RIV-01-04-F"
        ? 3
        : undefined
    );
  }, [symbol]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const provider = new ethers.providers.JsonRpcProvider(rpc);
        const addDDD = "0xA69D8E6995BF89BC0206a6C983874D8776dE0C4E";
        const vaultContract = new ethers.Contract(
          addDDD,
          masterChef.abi,
          provider
        );

        const intValue = parseInt(
          (await vaultContract.poolLength()).toString(),
          10
        );

        const updatedPoolTokens: PoolToken[] = [];
        for (let i = 0; i < intValue; i++) {
          const share = await vaultContract.lpToken(i);
          const addressMappedToIndex = "";
          updatedPoolTokens.push({
            index: i,
            share,
            address: addressMappedToIndex,
          });
        }
        setPoolTokens(updatedPoolTokens);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const withdraw = async () => {
    try {
      if (!signer) {
        console.log("Connect wallet to perform this action");
        return;
      }

      if (vaultNumber === undefined) {
        console.log("Vault number is undefined");
        return;
      }

      const addDDD = "0xA69D8E6995BF89BC0206a6C983874D8776dE0C4E";
      const vaultContract = new ethers.Contract(addDDD, masterChef.abi, signer);
      const intValue = await vaultContract.withdraw(vaultNumber, 0, {
        gasLimit: 300000,
      });
      setIsTransactionOnGoing(true);
      showSuccess("Claim successful!");
    } catch (error) {
      showError("Error claiming");
      console.error("Error claiming:", error);
    } finally {
      setIsTransactionOnGoing(false);
    }
  };

  return (
    <>
      <Toast ref={toast} />
      {signer ? (
        <button
          className="mt-05 btn-riv-manage whitespace-nowrap"
          type="button"
          onClick={withdraw}
        >
          Claim
        </button>
      ) : (
        <ConnectBtn />
      )}
    </>
  );
};

export default ClaimButtonDegen;
