import { ethers } from "ethers";
import { useEffect, useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { useAccount, useSwitchNetwork, useNetwork } from "wagmi";
import { backendApiUrlBase } from "../../constants/global";
import ConnectBtn from "../../component/ConnectBtn";
import coins from "../../assets/images/manageImageDegen.png";
import masterChef from "../../abi/out/MasterChefNew.sol/MasterChefNew.json";
import riveraAutoCompoundingVaultV2WhitelistedJson from "../../abi/out/RiveraAutoCompoundingVaultV2Whitelisted.sol/RiveraAutoCompoundingVaultV2Whitelisted.json";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import ClaimButtonDegen from "./ClaimButton";
import ManageBtnDegen from "./manageBtn";
import arrowUpImg from "../../assets/images/arrow-up-right.png";

interface VaultItem {
  _id: string;
  symbol: string;
  displayName: string;
  strategyType: string;
  name: string;
  chain?: number;
  masterChef: string | null;
  vaultAddress: string;
  denominationAssetAddress: string;
  chainRPC: string;
  totalAssets: string;
  totalSupply: string;
  denominationDecimal: number;
  apr: {
    apr: number;
  } | null;
  totalStaked?: number;
  totalStakedDollar?: number;
  myBalance?: number;
  myBalanceNonDollar?: number;
  myStake?: number;
  myStakeDollar?: number;
  totalExtraPerBlock?: number;
  totalSlushPerBlock?: number;
  mywtlosEarning?: number;
  myslushEarning?: number;
}

function ManageDegen() {
  // const address = "0x6C47DCbE1985B717488a2aA6Aeed209618d93c5E";
  const { chain } = useNetwork();
  const [vaults, setVaults] = useState<VaultItem[]>([]);

  const { address, isConnected } = useAccount();

  const [isDataLoading, setIsDataLoading] = useState(false);
  const [diff, setDiff] = useState(0);
  const [walletBalance, setWalletBalance] = useState<any>(0);
  const toast = useRef<Toast>(null);
  const addDDD = "0xA69D8E6995BF89BC0206a6C983874D8776dE0C4E";
  const [vaultNumberMyStake, setVaultNumberMyStake] = useState<
    number | undefined
  >();
  const [vaultNumberTotalStaked, setVaultNumberTotalStaked] = useState<
    number | undefined
  >();
  const [vaultNumberBalance, setVaultNumberBalance] = useState<
    number | undefined
  >();

  const showSuccess = (message: string) => {
    toast.current?.show({
      severity: "success",
      summary: "Success",
      detail: message,
      life: 3000,
    });
  };

  const showWarn = (header: string, message: string) => {
    toast.current?.show({
      severity: "warn",
      summary: header,
      detail: message,
      life: 3000,
    });
  };

  const showError = (message: string) => {
    toast.current?.show({
      severity: "error",
      summary: "Error",
      detail: message,
      life: 3000,
    });
  };
  const getTwoDecimal = (num: any) => {
    const roundedNum = Math.floor(num * 100) / 100;
    return roundedNum.toFixed(2);
  };
  const fnum = (x: any) => {
    if (isNaN(x)) return x;

    if (x < 999) {
      return getTwoDecimal(x);
    }

    if (x < 1000) {
      return getTwoDecimal(x);
    }

    if (x < 10000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 100000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 1000000) {
      return getTwoDecimal(x / 1000) + "K";
    }

    if (x < 10000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000) {
      return getTwoDecimal(x / 1000000) + "M";
    }

    if (x < 1000000000000) {
      return getTwoDecimal(x / 1000000000) + "B";
    }

    return "1T+";
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsDataLoading(true);
        const response = await fetch(`${backendApiUrlBase}vaults`);
        const data = await response.json();
        const filteredVaults = data.filter(
          (vault: any) =>
            vault.masterChef === "0xA69D8E6995BF89BC0206a6C983874D8776dE0C4E"
        );

        setVaults(filteredVaults);

        const provider = new ethers.providers.JsonRpcProvider(
          "https://rpc.degen.tips"
        );
        const response2 = await fetch(
          "https://api.rivera.money/oracle/coingecko"
        );
        const data2 = await response2.json();
        let priceData: any = null;
        let pricePro: number | null = null;
        let priceDegen: number | null = null;
        let priceweth: number | null = null;
        priceData = data2.find(
          (item: any) =>
            item.denominationAssetAddress ===
            "0xa051a2cb19c00ecdffae94d0ff98c17758041d16"
        );
        pricePro = priceData ? Number(priceData.priceUSD) : null;
        priceData = data2.find(
          (item: any) =>
            item.denominationAssetAddress ===
            "0xeb54dacb4c2ccb64f8074eceea33b5ebb38e5387"
        );
        priceDegen = priceData ? Number(priceData.priceUSD) : null;
        priceData = data2.find(
          (item: any) =>
            item.denominationAssetAddress ===
            "0xf058eb3c946f0eaeca3e6662300cb01165c64ede"
        );
        priceweth = priceData ? Number(priceData.priceUSD) : null;

        const updatedVaults = await Promise.all(
          filteredVaults.map(async (vault: any) => {
            const vaultContractERC = new ethers.Contract(
              vault.vaultAddress,
              riveraAutoCompoundingVaultV2WhitelistedJson.abi,
              provider
            );
            let assetConversion = vault.totalAssets / vault.totalSupply;
            const vaultContract = new ethers.Contract(
              "0xA69D8E6995BF89BC0206a6C983874D8776dE0C4E",
              masterChef.abi,
              provider
            );
            const share = await vaultContractERC.balanceOf(
              "0xA69D8E6995BF89BC0206a6C983874D8776dE0C4E"
            );

            const vaultNumbers: { [key: string]: number } = {
              "RIV-01-01-Y": 0,
              "RIV-01-02-Y": 1,
              "RIV-01-03-Y": 2,
              "RIV-01-04-F": 3,
            };
            // total stake
            let denonimationDecimal2 = 18;
            const totalStaked = Number(
              share / Math.pow(10, denonimationDecimal2)
            ).toFixed(2);
            let totalStakedDollar = assetConversion * share;
            totalStakedDollar =
              totalStakedDollar / Math.pow(10, denonimationDecimal2);
            let priceUSD = 0;
            if (vaultNumbers[vault.symbol] === 0) {
              priceUSD = priceDegen !== null ? priceDegen : 1;
            } else {
              priceUSD = priceweth !== null ? priceweth : 1;
            }
            totalStakedDollar = priceUSD * totalStakedDollar;

            totalStakedDollar = Number(totalStakedDollar.toFixed(2));

            let denonimationDecimal = 18;
            let userBalance = await vaultContractERC.balanceOf(address);

            const userBalaceNonDollar = ethers.utils.formatUnits(
              userBalance,
              denonimationDecimal
            );
            userBalance = assetConversion * userBalance;
            userBalance = userBalance / Math.pow(10, denonimationDecimal);
            userBalance = Number(userBalance).toFixed(2);
            userBalance = priceUSD * userBalance;
            userBalance = Number(userBalance).toFixed(2);
            // my stake
            const userInfo = await vaultContract.userInfo(
              vaultNumbers[vault.symbol],
              address
            );
            const pendingExtra = await vaultContract.pendingExtra(
              vaultNumbers[vault.symbol],
              address
            );
            const pendingSlushs = await vaultContract.pendingSlushs(
              vaultNumbers[vault.symbol],
              address
            );
            const myStake = ethers.utils.formatUnits(userInfo.amount, 18);

            const mywtlosEarning = Number(
              ethers.utils.formatUnits(pendingExtra, 18)
            ).toFixed(2);

            const myslushEarning = Number(
              ethers.utils.formatUnits(pendingSlushs, 18)
            ).toFixed(2);

            let myStakeDollar = assetConversion * userInfo.amount;
            myStakeDollar = myStakeDollar / Math.pow(10, denonimationDecimal);
            myStakeDollar = myStakeDollar * priceUSD;
            myStakeDollar = Number(myStakeDollar.toFixed(2));

            // APR extra is degen and slush is proxy
            let totalExtraPerBlock = Number(
              await vaultContract.totalExtraPerBlock()
            );
            let totalSlushPerBlock = Number(
              await vaultContract.totalSlushsPerBlock()
            );
            let multiplicationRatio = vaultNumbers[vault.symbol] === 3 ? 1 : 0;

            let blockPersecond = 0.45;
            // if (pricePro !== null) {
            //   totalSlushPerBlock =
            //     ((totalSlushPerBlock / Math.pow(10, 18)) *
            //       multiplicationRatio *
            //       blockPersecond *
            //       3600 *
            //       24 *
            //       365 *
            //       pricePro *
            //       100) /
            //     totalStakedDollar;
            // } else {
            //   totalSlushPerBlock =
            //     ((totalSlushPerBlock / Math.pow(10, 18)) *
            //       multiplicationRatio *
            //       blockPersecond *
            //       3600 *
            //       24 *
            //       365 *
            //       1 *
            //       100) /
            //     totalStakedDollar;
            // }

            if (priceDegen !== null) {
              totalExtraPerBlock =
                ((totalExtraPerBlock / 10 ** 18) *
                  blockPersecond *
                  multiplicationRatio *
                  3600 *
                  24 *
                  365 *
                  priceDegen *
                  100) /
                totalStakedDollar;
            } else {
              totalExtraPerBlock =
                ((totalExtraPerBlock / 10 ** 18) *
                  blockPersecond *
                  multiplicationRatio *
                  3600 *
                  24 *
                  365 *
                  1 *
                  100) /
                totalStakedDollar;
            }

            if (
              totalExtraPerBlock === 0 ||
              isNaN(totalExtraPerBlock) ||
              totalExtraPerBlock === Infinity
            ) {
              totalExtraPerBlock = 0;
            }

            return {
              ...vault,
              totalStaked: Number(totalStaked),
              totalStakedDollar: Number(totalStakedDollar),
              myBalance: Number(userBalance),
              myBalanceNonDollar: userBalaceNonDollar,
              myStake: myStake,
              myslushEarning: Number(myslushEarning),
              mywtlosEarning: Number(mywtlosEarning),
              myStakeDollar: Number(myStakeDollar),
              totalExtraPerBlock: Number(totalExtraPerBlock).toFixed(2),
              totalSlushPerBlock: Number(totalSlushPerBlock).toFixed(2),
            };
          })
        );

        setVaults(updatedVaults);
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsDataLoading(false);
      }
    };

    if (isConnected) {
      fetchData();
    }
  }, [isConnected, address]);

  useEffect(() => {
    if (chain) {
      setDiff(chain.id); // Update the diff state with the current network ID
    }
  }, [chain]);

  const switchNetwork = useSwitchNetwork();
  const networkSwitchHandler = (networkId: number) => {
    (switchNetwork as any).switchNetwork(networkId);
  };
  const representativeBodyTemplate = (rowData: VaultItem) => {
    const [part1, part2] = rowData.displayName.split("-");

    const img1 = `../image/assets/${part1.toLowerCase()}.png`;
    const img2 = `../image/assets/${part2.toLowerCase()}.png`;
    const targetUrl = `https://app.rivera.money/#/vault/${rowData.vaultAddress}_666666666`;

    return (
      <div className="justify-content-between d-flex align-items-start whitespace-nowrap">
        <div className="d-flex align-items-start im_dark_lightText">
          <div className="d-flex">
            <img src={img1} alt="img1" className="btc_asst_img_width" />
            <img src={img2} alt="img2" className="btc_img_width" />
          </div>
          <a href={targetUrl} className="cust-link">
            <div>
              <div className="vault_header_font_size">
                {rowData.displayName}
              </div>
              <div className="card_snd_clr fnt_14 notMobileDevice imLight">
                {rowData.strategyType}
              </div>
            </div>
          </a>
          <a href={targetUrl} className="">
            <img src={arrowUpImg} alt="website" className="" />
          </a>
        </div>
      </div>
    );
  };

  // const representativeBodyTemplateAPR = (rowData: VaultItem) => {
  //   const img1 = `../image/assets/proxy.png`;
  //   const img2 = `../image/assets/wdegen.png`;
  //   return (
  //     <div className="d-flex gap2 flex-column ">
  //       <div className="d-flex">
  //         <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
  //           <img src={img1} className="btc_asst_img_width_10" />
  //           {isDataLoading ? (
  //             <>
  //               <Skeleton width="3rem" className="mb-2"></Skeleton>
  //             </>
  //           ) : (
  //             <>{fnum(rowData.totalSlushPerBlock)}%</>
  //           )}
  //         </span>
  //       </div>
  //       <div className="d-flex">
  //         <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
  //           <img src={img2} className="btc_asst_img_width_10" />
  //           {isDataLoading ? (
  //             <>
  //               <Skeleton width="3rem" className="mb-2"></Skeleton>
  //             </>
  //           ) : (
  //             <>{fnum(rowData.totalExtraPerBlock)}%</>
  //           )}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };
  const representativeBodyTemplateAPR = (rowData: VaultItem) => {
    const img1 = `../image/assets/proxy.png`;
    const img2 = `../image/assets/wdegen.png`;

    // "RIV-01-01-Y": 0,
    //               "RIV-01-02-Y": 1,
    //               "RIV-01-03-Y": 2,
    //               "RIV-01-04-F": 3,

    if (
      rowData.symbol === "RIV-01-01-Y" ||
      rowData.symbol === "RIV-01-02-Y" ||
      rowData.symbol === "RIV-01-03-Y"
    ) {
      return (
        <div className="d-flex gap2 flex-column">
          <div className="d-flex">
            <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
              <img src={img1} className="btc_asst_img_width_10" />
              0.00%
            </span>
          </div>
          <div className="d-flex">
            <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
              <img src={img2} className="btc_asst_img_width_10" />
              0.00%
            </span>
          </div>
        </div>
      );
    } else if (rowData.symbol === "RIV-01-04-F") {
      return (
        <div className="d-flex gap2 flex-column">
          <div className="d-flex">
            <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
              <img src={img2} className="btc_asst_img_width_10" />
              {isDataLoading ? (
                <>
                  <Skeleton width="3rem" className="mb-2"></Skeleton>
                </>
              ) : (
                <>{fnum(rowData.totalExtraPerBlock)}%</>
              )}
            </span>
          </div>
        </div>
      );
    }
  };

  // const representativeBodyTemplateEarning = (rowData: VaultItem) => {
  //   const img1 = `../image/assets/proxy.png`;
  //   const img2 = `../image/assets/wdegen.png`;
  //   return (
  //     <div className="d-flex gap2 flex-column ">
  //       <div className="d-flex">
  //         <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
  //           <img src={img1} className="btc_asst_img_width_10" />
  //           {isDataLoading ? (
  //             <>
  //               <Skeleton width="3rem" className="mb-2"></Skeleton>
  //             </>
  //           ) : (
  //             <>{fnum(rowData.myslushEarning)}</>
  //           )}
  //         </span>
  //       </div>
  //       <div className="d-flex">
  //         <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
  //           <img src={img2} className="btc_asst_img_width_10" />
  //           {isDataLoading ? (
  //             <>
  //               <Skeleton width="3rem" className="mb-2"></Skeleton>
  //             </>
  //           ) : (
  //             <>{fnum(rowData.mywtlosEarning)}</>
  //           )}
  //         </span>
  //       </div>
  //     </div>
  //   );
  // };
  const representativeBodyTemplateEarning = (rowData: VaultItem) => {
    const img1 = `../image/assets/proxy.png`;
    const img2 = `../image/assets/wdegen.png`;

    if (
      rowData.symbol === "RIV-01-01-Y" ||
      rowData.symbol === "RIV-01-02-Y" ||
      rowData.symbol === "RIV-01-03-Y"
    ) {
      return (
        <div className="d-flex gap2 flex-column">
          <div className="d-flex">
            <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
              <img src={img1} className="btc_asst_img_width_10" />
              {isDataLoading ? (
                <Skeleton width="3rem" className="mb-2" />
              ) : (
                <>{fnum(rowData.myslushEarning)}</>
              )}
            </span>
          </div>
          <div className="d-flex">
            <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
              <img src={img2} className="btc_asst_img_width_10" />
              {isDataLoading ? (
                <Skeleton width="3rem" className="mb-2" />
              ) : (
                <>{fnum(rowData.mywtlosEarning)}</>
              )}
            </span>
          </div>
        </div>
      );
    } else {
      return (
        <div className="d-flex gap2 flex-column">
          <div className="d-flex">
            <span className="redHatFont fnt_wgt_600 flexTwoBtn2">
              <img src={img2} className="btc_asst_img_width_10" />
              {isDataLoading ? (
                <Skeleton width="3rem" className="mb-2" />
              ) : (
                <>{fnum(rowData.mywtlosEarning)}</>
              )}
            </span>
          </div>
        </div>
      );
    }
  };

  const representativeBodyTemplateSybmbol = (rowData: VaultItem) => {
    const targetUrl = `https://app.rivera.money/#/vault/${rowData.vaultAddress}_666666666`;
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex">
          <a
            href={targetUrl}
            className="redHatFont fnt_wgt_600 cust-link"
            style={{ whiteSpace: "nowrap" }}
          >
            {rowData.symbol}
          </a>
          <a href={targetUrl} className="">
            <img src={arrowUpImg} alt="website" className="" />
          </a>
        </div>
      </div>
    );
  };
  const representativeBodyTemplateMasterBalance = (rowData: VaultItem) => {
    setVaultNumberBalance(
      rowData?.symbol === "RIV-01-01-Y"
        ? 0
        : rowData?.symbol === "RIV-01-02-Y"
        ? 1
        : rowData?.symbol === "RIV-01-04-F"
        ? 3
        : undefined
    );
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600">
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>{fnum(rowData.myBalanceNonDollar)}</>
            )}
          </span>
        </div>
        <div
          className="flex-exchng font_300"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <span>
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>(${fnum(rowData.myBalance)})</>
            )}
          </span>
        </div>
      </div>
    );
  };
  const representativeBodyTemplateMasterMYStake = (rowData: VaultItem) => {
    setVaultNumberMyStake(
      rowData?.symbol === "RIV-01-01-Y"
        ? 0
        : rowData?.symbol === "RIV-01-02-Y"
        ? 1
        : rowData?.symbol === "RIV-01-04-F"
        ? 3
        : undefined
    );
    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600">
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>{fnum(rowData.myStake)}</>
            )}
          </span>
        </div>
        <div
          className="flex-exchng font_300"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <span>
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>(${fnum(rowData.myStakeDollar)})</>
            )}
          </span>
        </div>
      </div>
    );
  };

  const representativeBodyTemplateTotalStaked = (rowData: VaultItem) => {
    setVaultNumberTotalStaked(
      rowData?.symbol === "RIV-01-01-Y"
        ? 0
        : rowData?.symbol === "RIV-01-02-Y"
        ? 1
        : rowData?.symbol === "RIV-01-04-F"
        ? 3
        : undefined
    );

    return (
      <div className="d-flex flex-column align-items-center justify-content-center">
        <div className="d-flex">
          <span className="redHatFont fnt_wgt_600">
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>{fnum(rowData.totalStaked)}</>
            )}
          </span>
        </div>
        <div
          className="flex-exchng font_300"
          style={{ whiteSpace: "pre-wrap" }}
        >
          <span>
            {isDataLoading ? (
              <>
                <Skeleton width="3rem" className="mb-2"></Skeleton>
              </>
            ) : (
              <>(${fnum(rowData.totalStakedDollar)})</>
            )}
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <Toast ref={toast} />
      <div className="custom-container">
        {isConnected ? (
          <div className="second_section_xRiv outer_section_first_xRiv">
            <div className="dsp_cont_first_section">
              <div className="wdth_40_first">
                <div className="holding_header_inner mt-2 stakeText redHatFont">
                  Stake Yield Tokens to earn $PROXY and $WDEGEN{" "}
                </div>
              </div>
              <div>
                <img
                  height={200}
                  src={coins}
                  alt="rocket img"
                  className="manageImage notMobileDevice"
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="second_section_xRiv outer_section_first_xRiv">
            <div className="dsp_cont_first_section">
              <div className="wdth_40_first">
                <div className="holding_header_inner mb-2 stakeText redHatFont">
                  Stake Yield Tokens to earn $TLOS and $SLUSH{" "}
                </div>
                <div className=" manage-earn-claimSection align-items-center">
                  <div className="dsp_around mb-2">
                    <ConnectBtn />
                  </div>
                </div>
              </div>
              <div>
                <img
                  height={200}
                  src={coins}
                  alt="rocket img"
                  className="manageImage notMobileDevice"
                />
              </div>
            </div>
          </div>
        )}
        <h2 className="redHatFont history_txt1 mt-1 ml-105">Active Vaults</h2>
        <div className="custom-container border2 mrg_all">
          <DataTable
            scrollable
            tableStyle={{ minWidth: "50rem" }}
            className="p-datatable-striped"
            // value={vaults}
            value={[...vaults].reverse()} // Reverse the vaults array
          >
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              body={representativeBodyTemplate}
              header="Vault"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray whitespace-nowrap"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              body={representativeBodyTemplateSybmbol}
              header="Yield Token"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray whitespace-nowrap"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText whitespace-nowrap"
              body={representativeBodyTemplateAPR}
              header="APR"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              body={representativeBodyTemplateTotalStaked}
              header="Total Staked"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText "
              body={representativeBodyTemplateMasterBalance}
              header="My Balance"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText"
              body={representativeBodyTemplateMasterMYStake}
              header="My Stake"
            />
            <Column
              style={{ width: "100px" }}
              headerClassName="im_header txt_gray whitespace-nowrap"
              className="im_dark_lightBG"
              bodyClassName="im_dark_lightText whitespace-nowrap "
              body={representativeBodyTemplateEarning}
              header="Earnings"
            />

            <Column
              headerClassName="im_header txt_gray "
              className="im_dark_lightBG"
              bodyClassName="im_header txt_gray "
              style={{ width: "120px" }}
              alignFrozen="right"
              body={(rowData: VaultItem) =>
                isConnected ? (
                  diff !== Number(666666666) ? (
                    <button
                      className="btn btn-riv-primary wdth_100 redHatFont whitespace-nowrap  fnt_wgt_600"
                      onClick={() => {
                        networkSwitchHandler(666666666);
                      }}
                      style={{ padding: "4px 8px", fontSize: "12px" }}
                    >
                      Switch to Degen L3
                    </button>
                  ) : (
                    <ClaimButtonDegen
                      vaultName={rowData.displayName}
                      symbol={rowData.symbol}
                      Img1={`../image/assets/${
                        rowData.displayName.toLowerCase().split("-")[0]
                      }.png`}
                      Img2={`../image/assets/${
                        rowData.displayName.toLowerCase().split("-")[1]
                      }.png`}
                      addressNew={rowData.vaultAddress}
                      rpc={rowData.chainRPC}
                      denominationDecimal={rowData.denominationDecimal}
                    />
                  )
                ) : (
                  <ConnectBtn />
                )
              }
            />
            <Column
              headerClassName="im_header txt_gray"
              className="im_header txt_gray"
              bodyClassName=" im_header txt_gray"
              style={{ width: "120px" }}
              alignFrozen="right"
              frozen
              body={(rowData: VaultItem) =>
                isConnected ? (
                  diff !== Number(666666666) ? (
                    <button
                      className="btn btn-riv-primary wdth_100 whitespace-nowrap redHatFont fnt_wgt_600"
                      onClick={() => {
                        networkSwitchHandler(666666666);
                      }}
                      style={{ padding: "4px 8px", fontSize: "12px" }}
                    >
                      Switch to Degen L3
                    </button>
                  ) : (
                    <ManageBtnDegen
                      vaultName={rowData.displayName}
                      symbol={rowData.symbol}
                      Img1={`../image/assets/${
                        rowData.displayName.toLowerCase().split("-")[0]
                      }.png`}
                      Img2={`../image/assets/${
                        rowData.displayName.toLowerCase().split("-")[1]
                      }.png`}
                      addressNew={rowData.vaultAddress}
                      rpc={rowData.chainRPC}
                      denominationDecimal={rowData.denominationDecimal}
                      denominationAssetAddress={
                        rowData.denominationAssetAddress
                      }
                      totalAssets={rowData.totalAssets}
                      totalSupply={rowData.totalSupply}
                      myBalance={rowData.myBalance}
                      myBalanceNonDollar={rowData.myBalanceNonDollar}
                      mystake={rowData.myStake}
                    />
                  )
                ) : (
                  <ConnectBtn />
                )
              }
            />
          </DataTable>
        </div>
      </div>
    </>
  );
}
export default ManageDegen;
