import React, { useState } from "react";
import { Sidebar } from "primereact/sidebar";
import { useNavigate } from "react-router-dom";
import ConnectBtn from "../../component/ConnectBtn";
import logoImg from "../../assets/images/logo.png";
import dashboardImg from "../../assets/images/sideBar/sideBar4.svg";
import discovervaultsImg from "../../assets/images/sideBar/sideBar1.svg";
import rewardsImg from "../../assets/images/sideBar/sideBar2.svg";
import bridgesImg from "../../assets/images/sideBar/sideBar3.svg";
import dArrow from "../../assets/images/downArrow.svg";
import uArrow from "../../assets/images/UPUPARROW.svg";
import earn from "../../assets/images/earnIcon.svg";
import bullImg from "../../assets/images/bullQaunt2.svg";
import portfolio1 from "../../assets/images/sideBar/Portfolio.svg";
import polygon from "../../assets/images/sideBar/polygon.svg";
import degen from "../../assets/images/sideBar/degen.svg";
import telos from "../../assets/images/sideBar/telos.svg";

interface HeadlessSidebarProps {
  className?: string;
}

const imagesMap: { [key: string]: string } = {
  Dashboard: dashboardImg,
  Discover: discovervaultsImg,
  Bridges: bridgesImg,
  Quant: bullImg,
  Rewards: rewardsImg,
  // "Airdrop ": airDrop,
  Earn: earn,
  Portfolio: portfolio1,
  Telos: telos,
  DegenL3: degen,
  Polygon: polygon,
};

const HeadlessSidebar: React.FC<HeadlessSidebarProps> = ({ className }) => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState<boolean>(false);
  const [openItems, setOpenItems] = useState<string[]>([]);

  const navigateToPage = (path: string) => {
    navigate(path);
    closeSidebar();
  };

  const openSidebar = () => {
    setVisible(true);
  };

  const closeSidebar = () => {
    setVisible(false);
  };

  const toggleItem = (label: string) => {
    setOpenItems((prevOpenItems) => {
      if (prevOpenItems.includes(label)) {
        return prevOpenItems.filter((item) => item !== label);
      } else {
        return [...prevOpenItems, label];
      }
    });
  };

  const handleMenuItemClick = (item: MenuItem) => {
    if (item.url) {
      navigateToPage(item.url);
    } else if (item.command) {
      item.command();
      closeSidebar();
    }
  };
  interface MenuItem {
    label: string;
    url?: string;
    command?: () => void;
    items?: MenuItem[];
  }

  const items = [
    {
      label: "Dashboard",
      url: "/",
      command: () => navigateToPage("/"),
    },
    {
      label: "Discover",
      url: "discover",
      command: () => navigateToPage("discover"),
    },
    {
      label: "Rewards",
      url: "rewards",
      command: () => navigateToPage("rewards"),
    },
    {
      label: "Portfolio",
      url: "/portfolio",
      command: () => {
        navigateToPage("/portfolio");
      },
    },
    {
      label: "Earn",
      className: "",
      target: "_blank",
      items: [
        {
          label: "Telos",
          command: () => {
            navigateToPage("/earn/telos");
          },
        },
        {
          label: "Polygon",
          command: () => {
            navigateToPage("/earn/polygon ");
          },
        },
        {
          label: "DegenL3",
          command: () => {
            navigateToPage("/earn/degen");
          },
        },
      ],
    },
    // {
    //   label: "Bridges",
    //   items: [
    //     { label: "Mantle Network", url: "https://bridge.mantle.xyz/" },
    //     {
    //       label: "Manta Pacific",
    //       url: "https://pacific-bridge.manta.network/",
    //     },
    //     {
    //       label: "Symbiosis",
    //       url: "https://app.symbiosis.finance/swap?chainIn=Ethereum&tokenIn=ETH&tokenOut=ETH",
    //     },
    //     {
    //       label: "Retrobridge",
    //       url: "https://app.retrobridge.io/?tokenFrom=ETH&destination=Arbitrum+One",
    //       target: "_blank",
    //     },
    //   ],
    // },
  ];

  return (
    <>
      <div className={`mobileTabletDevice ${className}`}>
        <button
          className="sidebar-toggle-button crsr_pntr"
          onClick={openSidebar}
        >
          <span className="bars-icon"></span>
        </button>

        <Sidebar
          visible={visible}
          onHide={closeSidebar}
          className="imDark border2Side"
          style={{ width: "300px" }}
        >
          <img
            src={logoImg}
            alt="logo"
            className="margBotSide redHatFont fnt_wgt_600"
          />
          <div className="bottom-border"></div>
          <div className="gpP-container">
            <ul className="sidebar-menu redHatFont fnt_wgt_600">
              {items.map((item, index) => (
                <li key={index}>
                  <div
                    className="sideFlex bgPurple mt-05 gapbtw crsr_pntr"
                    onClick={() => {
                      toggleItem(item.label);
                      handleMenuItemClick(item);
                    }}
                  >
                    <div className="sideFlex">
                      <img
                        src={imagesMap[item.label]}
                        alt={item.label}
                        className="sidebar-icon"
                      />
                      <span className="menu-item redHatFont whitespace-nowrap imLight fnt_wgt_600 crsr_pntr">
                        {item.label}
                      </span>
                    </div>
                    <div
                      className=""
                      onClick={() => {
                        toggleItem(item.label);
                        handleMenuItemClick(item);
                      }}
                    >
                      {item.items && (
                        <img
                          src={openItems.includes(item.label) ? uArrow : dArrow}
                          alt="arrow"
                          className={`arrow-icon mrSide-1 crsr_pntr ${
                            openItems.includes(item.label) ? "open" : "closed"
                          }`}
                          onClick={() => toggleItem(item.label)}
                        />
                      )}
                    </div>
                  </div>
                  {item.items && openItems.includes(item.label) && (
                    <>
                      <div className="bottom-border"></div>
                      <ul className="submenu  redHatFont fnt_wgt_600">
                        {item.items.map((subItem, subIndex) => (
                          <li
                            key={subIndex}
                            className=" bgPurpleTWO bottom-border crsr_pntr imLight wdth_40SIDE fnt_wgt_600"
                            onClick={() => {
                              handleMenuItemClick(subItem);
                              closeSidebar();
                            }}
                          >
                            <span className=" redHatFont crsr_pntr  mrLIST fnt_wgt_600">
                              {imagesMap[subItem.label] && (
                                <img
                                  src={imagesMap[subItem.label]}
                                  alt={item.label}
                                  className="sidebar-sub-icon"
                                />
                              )}
                              {subItem.label}
                            </span>
                          </li>
                        ))}
                      </ul>
                    </>
                  )}
                </li>
              ))}
            </ul>
            <div className="mtConnectSide">
              <ConnectBtn />
            </div>
          </div>
        </Sidebar>
      </div>
    </>
  );
};

export default HeadlessSidebar;
