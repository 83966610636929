import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../App.css';
import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SelectButton } from 'primereact/selectbutton';
import { backendApiUrlBase } from '../../constants/global';
import { Dropdown } from 'primereact/dropdown';
import { Tooltip } from 'primereact/tooltip';
import infoCircleImg from '../../assets/images/info_circle.svg';
import { Skeleton } from 'primereact/skeleton';
import boosted from '../../assets/images/Boosted 2.svg'
import { getFormated } from '../common/format';

interface VaultData {
    identifier: string;
    displayName: string;
    tvlUSD: number;
    strategyType: string;
    isStable: boolean;
    chain: string;
    token1image: string;
    token2image: string;
    apr: {
        xrivPool: number;
        apr: number;
        lpAPR: number;
    };

    APRNew: number;
}

function VaultList() {
    const [vaultType, setVaultType] = useState('allVault');
    const [selectedStrategyType, setSelectedStrategyType] = useState<string>("All");
    const [vaultOptions] = useState([
        { label: 'All Vault', value: 'allVault' },
        { label: 'Stable', value: 'stable' },
        { label: 'Volatile', value: 'volatile' },
        { label: 'Boosted', value: 'boosted' }
    ]);

    let { chainName, exchangeName } = useParams();
    const [products, setProducts] = useState<VaultData[]>([]);
    const [loading, setLoading] = useState(false);
    const [actualData, setActualData] = useState<VaultData[]>([]);
    const navigate = useNavigate();
    const items: any[] = Array.from({ length: 5 }, (v, i) => ({ Vault: i, name: `Item ${i}`, category: `TVL ${i}`, quantity: i }));

    useEffect(() => {
        (async () => {
            setLoading(true);
            try {
                const data = await fetch(`${backendApiUrlBase}vaults/?dex=${exchangeName}`);
                const jsonData = await data.json();

                let tvlUSD = 0;

                const modifiedData = jsonData.map((e: any) => {
                    const displayNameParts = e.displayName.split("-");
                    const asset1 = displayNameParts[0].toLowerCase();
                    const asset2 = displayNameParts[1].toLowerCase();

                    const lpAPR = e.apr?.lpAPR;
                    const APR = e.apr?.apr;

                    e.lpNew = lpAPR;
                    e.APRNew = APR;
                    tvlUSD += e.tvlUSD;
                    e.token1image = `../image/assets/${asset1}.png`;
                    e.token2image = `../image/assets/${asset2}.png`;

                    return e;
                });

                const sortedData = modifiedData.sort((a: VaultData, b: VaultData) => b.tvlUSD - a.tvlUSD);

                setActualData(sortedData);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching data:', error);
                setLoading(false);
            }
        })();
    }, []);

    useEffect(() => {
        allVaultListFilter(vaultType, selectedStrategyType, chainName);
    }, [chainName, actualData, selectedStrategyType]);

    const vaultTypeFun = (input: any) => {
        setVaultType(input.value);
        allVaultListFilter(input.value, selectedStrategyType, chainName);
    }

    const allVaultListFilter = (vaultType: any, strategyType: string, chainName: any) => {
        let filterProducts;

        if (!vaultType || vaultType === 'allVault') {
            filterProducts = actualData.filter((element: any) =>
                (strategyType === "All" || element.strategyType === strategyType) &&
                (element.chain === chainName)
            );
        }
        else {
            filterProducts = actualData.filter((element: any) =>
                (strategyType === "All" || element.strategyType === strategyType) &&
                (element.chain === chainName)

            );

            if (vaultType === 'volatile') {
                filterProducts = filterProducts.filter((element: any) =>
                    !element.tags.includes("Stable Pair")
                );
            }
            else if (vaultType === 'stable') {
                filterProducts = filterProducts.filter((element: any) =>
                    element.tags.includes("Stable Pair")
                );
            }

            else if (vaultType === 'boosted') {
                filterProducts = filterProducts.filter((element: any) =>
                    element.tags.includes("Boosted")
                );
            }
        }


        setProducts(filterProducts);
    };

    const onRowSelect = (event: any) => {
        navigate('/discover/' + exchangeName + '/' + chainName + '/' + event?.data.identifier);
    };

    const getStrategyTypeOptions = () => {
        const strategyTypes = new Set<string>(actualData.map((element: any) => element.strategyType));
        let options: { label: string, value: string }[] = [];
        options.push({ label: "Select Strategy", value: "All" });
        strategyTypes.forEach((type: string) => {
            options.push({ label: type, value: type });
        });
        return options;
    };

    const renderHeader = () => {
        return (
            <div className="d-flex justify-content-between ">
                <div className=''>
                    <SelectButton value={vaultType}
                        onChange={(e) => vaultTypeFun(e)}
                        options={vaultOptions}
                    />
                </div>
                <div>
                    <span className="p-input-icon-left">
                        <i className="pi pi-search rd200" />
                        <div className='mt-05'>
                            <Dropdown
                                style={{ borderRadius: '0px' }}
                                className='redHatFont dropDown1  special-menu-item'
                                value={selectedStrategyType}
                                options={getStrategyTypeOptions()}
                                onChange={(e) => {
                                    setSelectedStrategyType(e.value);
                                    allVaultListFilter(vaultType, e.value, chainName);
                                }}
                            />
                        </div>
                    </span>
                </div>
            </div>
        );
    };
    const header = renderHeader();
    const renderDataTable = () => {
        if (loading) {
            return (
                <>
                    <div className='border2'>
                        <DataTable

                            tableStyle={{ minWidth: '50rem' }}
                            header={header}
                            value={items} className="p-datatable-striped">
                            <Column
                                headerClassName='im_header'
                                className='im_dark_lightBG'
                                bodyClassName='im_dark_lightText '
                                field="code" header="Vault" style={{ width: '45%', height: '65px' }} body={<Skeleton />}></Column>
                            <Column
                                headerClassName='im_header'
                                className='im_dark_lightBG'
                                bodyClassName='im_dark_lightText '

                                field="name" header="Vault Symbol" style={{ width: '25%', height: '50px' }} body={<Skeleton />}></Column>
                            <Column
                                headerClassName='im_header'
                                className='im_dark_lightBG'
                                bodyClassName='im_dark_lightText '

                                field="category" header="TVL" style={{ width: '10%', height: '50px' }} body={<Skeleton />}></Column>
                            <Column

                                headerClassName='im_header whitespace-nowrap'
                                className='im_dark_lightBG'
                                bodyClassName='im_dark_lightText '
                                field="quantity" header="Total APR(24h)" style={{ width: '25%', height: '50px' }} body={<Skeleton />}></Column>
                        </DataTable>
                    </div>
                </>
            );
        } else {
            return (
                <DataTable
                    value={products}
                    paginator
                    paginatorClassName='im_dark_lightBG'
                    rows={10}
                    rowsPerPageOptions={[5, 10, 25, 50]}
                    tableStyle={{ minWidth: '50rem' }}
                    selectionMode="single"
                    onRowSelect={onRowSelect}
                    showGridlines
                    header={renderHeader()}
                    emptyMessage="No record found."
                    dataKey="id"
                >
                    <Column
                        headerClassName='im_header'
                        className='im_dark_lightBG'
                        field="code"
                        header="Vault"
                        body={(rowData) => representativeBodyTemplate(rowData)}
                        bodyClassName=''
                    />
                    <Column
                        headerClassName='im_header'
                        className='im_dark_lightBG'
                        field="symbol"
                        header="Vault Symbol"
                        bodyClassName='im_dark_lightText'
                    />
                    <Column
                        headerClassName='im_header'
                        className='im_dark_lightBG'
                        field="tvlUSD"
                        header="TVL"
                        body={tvlBodyTemplate}
                        bodyClassName='im_dark_lightText'
                    />
                    <Column
                        headerClassName='im_header'
                        className='im_dark_lightBG'
                        field="quantity"
                        header="Total APR(24h)"
                        body={renderAPR}
                        bodyClassName='im_dark_lightText'
                    />
                </DataTable>
            );
        }
    };

    const renderAPR = (rowData: any) => {

        const lpapr = rowData.lpNew * 100
        const apapr = rowData.APRNew * 100
        const sum = lpapr + apapr
        return (
            <>
                <div className='d-flex align-items-center'>
                    <div className='holding_val'>{sum.toFixed(2)}%</div>
                    <img className={`toolTipPerformance-${rowData.identifier} ml_5`} src={infoCircleImg} alt='info' />
                    <Tooltip target={`.toolTipPerformance-${rowData.identifier}`} mouseTrack mouseTrackLeft={10}
                        className="custom-tooltip"
                    >
                        LP: {lpapr.toFixed(2)}%<br />
                        {apapr !== 0 && `xRIV: ${apapr.toFixed(2)}%`
                        }
                    </Tooltip>
                </div>
            </>
        );
    };
    const representativeBodyTemplate = (rowData: any) => {
        const isBoosted = selectedStrategyType === "boosted" || rowData.tags.includes("Boosted");
        return (
            <div className='justify-content-between d-flex align-items-start'>
                <div className='d-flex align-items-start im_dark_lightText'>
                    <div className='d-flex'>
                        <img src={rowData.token1image} alt='btc img' className='btc_asst_img_width' />
                        <img src={rowData.token2image} alt='btc img' className='btc_img_width' />
                    </div>
                    <div>
                        <div className='vault_header_font_size'>{rowData.displayName}</div>
                        <div className='card_snd_clr fnt_14 notMobileDevice imLight'>{rowData.strategyType}</div>
                    </div>
                </div>
                {isBoosted &&
                    <div className='flexT redHatFont align-items-center '>
                        <div className='card_snd_clr secondary_color fnt_wgt_60B notMobileDevice'>Boosted</div>
                        <img className='mr-1' src={boosted} alt='boosted' width={15} />
                    </div>
                }

            </div>
        );
    };

    const tvlBodyTemplate = (rowData: any) => {
        return (
            <div className='im_dark_lightText'>
                ${getFormated(rowData.tvlUSD)}
            </div>
        );
    };
    return (
        renderDataTable()
    );
}

export default VaultList;
